import React, { useState } from "react";
import { ImageContainer, Show } from "../components";

import styles from "./Control.module.css";

const Control = ({ run }) => {
  const [base64, setBase64] = useState(null);

  const process = async () => {
    const result = await run();

    if (result.base64) {
      setBase64(result.base64);
    }
  };

  return (
    <div className={styles.control}>
      <button type="button" className={styles.button} onClick={process}>
        Run
      </button>
      <Show when={base64 !== null}>
        <ImageContainer
          src={base64}
          onClose={() => {
            URL.revokeObjectURL(base64);
            setBase64(null);
          }}
        />
      </Show>
    </div>
  );
};

export { Control };
