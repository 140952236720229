import React from "react";
import { Link } from "react-router-dom";

import styles from "./Card.module.css";

const Card = ({ children }) => {
  return <div className={styles.card}>{children}</div>;
};

Card.Link = ({ children, to }) => {
  return (
    <Link to={to} className={styles.link}>
      {children}
    </Link>
  );
};

Card.Content = ({ children }) => {
  return <div className={styles.content}>{children}</div>;
};

export { Card };
