const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 32px)",
        fontSize: "2rem",
        fontWeight: "bold",
      }}
    >
      <h1>404</h1>
      <p>Page not found</p>
    </div>
  );
};

export default NotFound;
