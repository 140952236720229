import { buildSvgDataURI } from "@artemis69/svg-foreignobject-screenshot";

import { renderToBase64Png } from "./shared";

const lang = "js";

const code = `
  import {
    buildSvgDataURI,
    renderToBase64Png,
  } from '@artemis69/svg-foreignobject-screenshot'

  const target = document.getElementById('first-example');

  const dataURI = await buildSvgDataURI(target, {
    width: target.offsetWidth,
    height: target.offsetHeight,
  });

  const base64png = await renderToBase64Png(dataURI);
`;

const run = async () => {
  const target = document.getElementById("first-example");

  const dataURI = await buildSvgDataURI(target, {
    width: target.offsetWidth,
    height: target.offsetHeight,
  });

  const base64png = await renderToBase64Png(dataURI);

  return { base64: base64png };
};

export { lang, code, run };
