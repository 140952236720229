import React from "react";
import styles from "./Title.module.css";

const Title = ({ children, as = "h1" }) => {
  const As = as;

  return <As className={styles.title}>{children}</As>;
};

const Subtitle = ({ children, as = "h2" }) => {
  const As = as;

  return <As className={styles.subtitle}>{children}</As>;
};

export { Title, Subtitle };
