import React from "react";
import { Container, Title, Code, Subtitle, Control, Live } from "../components";

import {
  firstExample,
  secondExample,
  thirdExample,
  fourthExample,
} from "../data";

const Link = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const AdvancedUsageLink = () => (
  <>
    <br />
    <Link href="https://github.com/Artemis69/svg-foreignobject-screenshot#advanced-usage">
      Read more
    </Link>
  </>
);

const TryItOut = () => {
  return (
    <>
      <Title>Try it out</Title>
      <Subtitle>Minimal code example</Subtitle>
      <div id="first-example">
        <Code language={firstExample.lang} code={firstExample.code} />
        <Control run={firstExample.run} />
      </div>
      <Subtitle>Images and SVG's</Subtitle>
      <p>
        If you want to shot images, you need to provide default fetcher, or
        write your own.
        <AdvancedUsageLink />
      </p>
      <div>
        <Code language={secondExample.lang} code={secondExample.code} />
        <Live code={secondExample.template} />
        <Control run={secondExample.run} />
      </div>
      <Subtitle>Filtering</Subtitle>
      <p>
        If you want to not load some resources, you can use filterer.
        <AdvancedUsageLink />
      </p>
      <div>
        <Code language={thirdExample.lang} code={thirdExample.code} />
        <Control run={thirdExample.run} />
      </div>
      <Subtitle>Fetcher</Subtitle>
      <p>
        If you need to proxy images or you want to use your own fetcher, you can
        use it.
      </p>
      <p>
        Dont forget that fetcher should return promise that resolves{" "}
        <a href="https://developer.mozilla.org/ru/docs/Web/HTTP/Basics_of_HTTP/Data_URIs">
          Data URL
        </a>
        .
        <AdvancedUsageLink />
      </p>
      <div>
        <Code language={fourthExample.lang} code={fourthExample.code} />
      </div>
    </>
  );
};

export default TryItOut;
