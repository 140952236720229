import {
  buildSvgDataURI,
  fetcher,
} from "@artemis69/svg-foreignobject-screenshot";

import { renderToBase64Png } from "./shared";

const lang = "js";

const code = `
  const filterer = (url) => {
    const extensions = ['.eot', '.ttf', '.otf', '.woff', '.woff2']

    for (const extension of extensions) {
      if (url.endsWith(extension)) {
        return false
      }
    }

    return true
  };

  const dataURI = await buildSvgDataURI(target, {
    width: target.offsetWidth,
    height: target.offsetHeight,
    fetcher,
    filterer,
  });

  const base64png = await renderToBase64Png(dataURI);
`;

const run = async () => {
  const target = document.getElementById("second-example");

  const filterer = (url) => {
    const extensions = [".eot", ".ttf", ".otf", ".woff", ".woff2"];

    for (const extension of extensions) {
      if (url.endsWith(extension)) {
        return false;
      }
    }

    return true;
  };

  const dataURI = await buildSvgDataURI(target, {
    width: target.offsetWidth,
    height: target.offsetHeight,
    fetcher,
    filterer,
  });

  const base64png = await renderToBase64Png(dataURI);

  return { base64: base64png };
};

export { lang, code, run };
