const renderToBase64Png = (dataURI) => {
  return new Promise((resolve) => {
    const img = document.createElement("img");

    const controller = () => {
      const canvas = document.createElement("canvas");

      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");

      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(img, 0, 0, img.width, img.height);

      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        resolve(url);
      });
    };

    img.src = dataURI;
    img.onload = controller;
    img.onerror = controller;
  });
};

export { renderToBase64Png };
