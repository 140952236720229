import React from "react";
import { Container, Subtitle, Title, Card } from "../components";

const Home = () => {
  return (
    <>
      <Title>svg-foreignobject-screenshot</Title>
      <Subtitle as={"p"}>
        Use SVG foreignObject to render images of HTML content
      </Subtitle>
      <Card>
        <Card.Link to={"/features"}>Features</Card.Link>
        <Card.Content>
          Here you can see what features are available
        </Card.Content>
      </Card>
      <Card>
        <Card.Link to={"/try-it-out"}>Try it out</Card.Link>
        <Card.Content>Go check it out for yourself</Card.Content>
      </Card>
    </>
  );
};

export default Home;
