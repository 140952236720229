import React from "react";
import { createPortal } from "react-dom";

import styles from "./ImageContainer.module.css";

const Portal = ({ children }) => {
  return createPortal(children, document.body);
};

const ImageContainer = ({ src, onClose }) => {
  return (
    <Portal>
      <div className={styles.container} onClick={onClose}>
        <div
          className={styles.image}
          style={{ backgroundImage: `url("${src}")` }}
        />
        <div className={styles.background} />
      </div>
    </Portal>
  );
};

export { ImageContainer };
