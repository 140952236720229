import React from "react";
import { Title, Subtitle, Container } from "../components";

const createShield = (method) => {
  return `https://img.shields.io/bundlephobia/${method}/@artemis69/svg-foreignobject-screenshot?style=for-the-badge`;
};

const Features = () => {
  return (
    <>
      <Title>Features</Title>
      <Subtitle>Size</Subtitle>
      <Container.Secondary>
        <img alt="npm bundle size" src={createShield("min")} />
        <img alt="npm bundle size" src={createShield("minzip")} />
      </Container.Secondary>
      <Subtitle>Simple, yet functional</Subtitle>
      <ul>
        <li>Does not build representationof the page</li>
        <li>Supports all properties that the browser supports</li>
      </ul>
      <Subtitle>Media</Subtitle>
      <ul>
        <li>Support's SVG</li>
        <li>
          Can be configured to use{" "}
          <a
            target="_blank"
            href="https://html2canvas.hertzen.com/configuration#:~:text=original%20source%20document.-,proxy,-null"
          >
            proxy
          </a>
        </li>
      </ul>
    </>
  );
};

export default Features;
