import React, { useEffect, useRef } from "react";
import Prism from "prismjs";
import dd from "dedent";

import "prism-themes/themes/prism-one-dark.css";

const Code = ({ code, language }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      Prism.highlightElement(ref.current);
    }
  }, []);

  return (
    <pre ref={ref} className={`language-${language}`}>
      <code className={`language-${language}`}>{dd(code)}</code>
    </pre>
  );
};

export { Code };
