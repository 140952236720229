const lang = "js";

const code = `
  const fetcher = (url) => {
    return new Promise(async resolve => {
      try {
        const response = await fetch(url)
  
        const blob = await response.blob()
  
        const reader = new FileReader()
  
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = () => resolve('')
  
        reader.readAsDataURL(blob)
      } catch {
        return resolve('')
      }
    })
  }

  const dataURI = await buildSvgDataURI(target, {
    width: target.offsetWidth,
    height: target.offsetHeight,
    fetcher,
  });

  const base64png = await renderToBase64Png(dataURI);
`;

export { lang, code };
