import styles from "./Live.module.css";

const prepare = (code) => {
  return code.replace(/<img/gm, '<img loading="lazy" fetchpriority="low" ');
};

const Live = ({ code }) => {
  return (
    <div
      className={styles.iframe}
      dangerouslySetInnerHTML={{ __html: prepare(code) }}
    />
  );
};

export { Live };
