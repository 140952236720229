import {
  buildSvgDataURI,
  fetcher,
} from "@artemis69/svg-foreignobject-screenshot";

import { renderToBase64Png } from "./shared";

import "./second-example.css";

const lang = "js";

const code = `
  import { fetcher } from '@artemis69/svg-foreignobject-screenshot';

  const target = document.getElementById("second-example");

  const dataURI = await buildSvgDataURI(target, {
    width: target.offsetWidth,
    height: target.offsetHeight,
    fetcher,
  });

  const base64png = await renderToBase64Png(dataURI);
`;

const template = `
  <div id="second-example">
    <img src="https://i.imgur.com/MkJ23PJ.jpg" alt="Rem and Ram are standing side by side.">
    <span>
      Some text with specific font-family. Look at me.
      <svg width="48" height="48" fill="#f67288" viewBox="0 0 24 24">
        <path fill-rule="evenodd" stroke="#f67288" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.995 7.23319C10.5455 5.60999 8.12832 5.17335 6.31215 6.65972C4.49599 8.14609 4.2403 10.6312 5.66654 12.3892L11.995 18.25L18.3235 12.3892C19.7498 10.6312 19.5253 8.13046 17.6779 6.65972C15.8305 5.18899 13.4446 5.60999 11.995 7.23319Z" clip-rule="evenodd"></path>
      </svg>
    </span>
  </div>
`;

const run = async () => {
  const target = document.getElementById("second-example");

  const dataURI = await buildSvgDataURI(target, {
    width: target.offsetWidth,
    height: target.offsetHeight,
    fetcher,
  });

  const base64png = await renderToBase64Png(dataURI);

  return { base64: base64png };
};

export { lang, code, template, run };
