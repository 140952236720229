import React from "react";
import styles from "./Container.module.css";

const Container = ({ children }) => {
  return <div className={styles.container}>{children ?? []}</div>;
};

Container.Secondary = ({ children }) => {
  return <div className={styles.containerSecondary}>{children ?? []}</div>;
};

export { Container };
